*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
}

/* Global scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 2px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 transparent;
}

/* Edge scrollbar styling */
* {
  -ms-overflow-style: none; /* Hide scrollbar in Edge */
}
*::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers (Chrome, Safari) */
}
